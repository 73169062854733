//数据类型
const getFieldTypes = `/gateway/hc-portal/ops/fieldTypes`;
//租户及社区编码列表
const getTenantList = `/gateway/hc-portal/szdz/tenantList`;
//小区编码列表
const getComplexList = `/gateway/hc-portal/szdz/complexList`;
//列表查询
const getPageListUrl = `/gateway/hc-portal/ops/page`;
//编辑
const editDataUrl = `/gateway/hc-portal/ops/edit`;
//详情
const getDataInfo = `/gateway/hc-portal/ops/info`;
//新增
const getAddData = `/gateway/hc-portal/ops/addData`;
//上报
const getPushDataUrl = `/gateway/hc-portal/ops/pushData`;
//日志
const getReportData = `/gateway/hc-portal/ops/pushDataLogs`;
export {
  getFieldTypes,
  getTenantList,
  getPageListUrl,
  editDataUrl,
  getComplexList,
  getDataInfo,
  getAddData,
  getPushDataUrl,
  getReportData,
};
